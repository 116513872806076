import { useContext } from "react";

import SelectInput from "components/ui/Form/inputs/Select";
import { IFormInputChange } from "models/form";
import { ITriggerDto } from "models/trigger";
import { AppContext } from "utils/context";

interface ITriggerSelectProps {
  label: string;
  currentTrigger: ITriggerDto | null;
  onSelect: (trigger: ITriggerDto | null) => void;
  hasError?: boolean;
}

export default function TriggerSelect({ label, currentTrigger, hasError = false, onSelect }: ITriggerSelectProps) {
  const { triggers } = useContext(AppContext);

  function handleSelect({ value }: IFormInputChange) {
    onSelect(value);
  }

  return <SelectInput className="select trigger-select" name="trigger" label={label} options={triggers} defaultValue={currentTrigger} getOptionLabel={(option) => option?.name || ""} isOptionEqualToValue={(option, value) => option !== null && value !== null && option.id === value.id} hasError={hasError} handleInputChange={handleSelect} />;
}
