import { useContext } from "react";

import SelectInput from "components/ui/Form/inputs/Select";
import { IFormInputChange } from "models/form";
import { IReceiverDto } from "models/receiver";
import { AppContext } from "utils/context";

interface IReceiverSelect {
  label: string;
  currentReceiver: IReceiverDto | null;
  onSelect: (receiver: IReceiverDto | null) => void;
  overrideOptions?: IReceiverDto[];
}

export default function ReceiverSelect({ label, currentReceiver, onSelect, overrideOptions }: IReceiverSelect) {
  const { receivers } = useContext(AppContext);

  function handleSelect({ value }: IFormInputChange) {
    onSelect(value);
  }

  return <SelectInput className="select receiver-select" name="receiver" label={label} options={overrideOptions || receivers} defaultValue={currentReceiver} getOptionLabel={(option) => option?.name || ""} isOptionEqualToValue={(option, value) => option !== null && value !== null && option.id === value.id} handleInputChange={handleSelect} />;
}
