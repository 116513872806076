import { Link, To } from "react-router-dom";

import "./GoBackLink.scss";
import { Icon } from "@crunchit/component-library";

interface IGoBackLinkProps {
  to: To;
  text: string;
}

export default function GoBackLink({ to, text }: IGoBackLinkProps) {
  return (
    <Link className="go-back-link" to={to}>
      <Icon height={16} width={16} url="https://crunchorderfrontend.blob.core.windows.net/icons/chevron-left.svg" />
      <span>{text}</span>
    </Link>
  );
}
