import { Button } from "@crunchit/component-library";
import { useTranslation } from "react-i18next";
import { To } from "react-router-dom";

import CloseLink from "components/ui/links/CloseLink";

import "./DetailsHeader.scss";

interface IDetailsHeaderProps {
  title: string;
  closeTo: To;
  handleDelete: () => void;
}

export default function DetailsHeader({ title, closeTo, handleDelete }: IDetailsHeaderProps) {
  const { t } = useTranslation();

  return (
    <header className="details-header">
      <div className="title">
        <h1>{title}</h1>

        <Button theme="danger-secondary" onClick={handleDelete}>
          {t("receivers:View.Delete")}
        </Button>
      </div>

      <CloseLink to={closeTo} />
    </header>
  );
}
