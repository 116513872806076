import { Autocomplete, TextField } from "@mui/material";
import { useFormControl } from "@mui/material/FormControl";
import { useState } from "react";
import { useTranslation } from "react-i18next";

import { IFormInputChange } from "models/form";

declare interface SelectInputProps<T> {
  options: T[];
  name: string;
  label: string;
  getOptionLabel: (option: T) => string;
  isOptionEqualToValue?: (option: T, value: T) => boolean;
  handleInputChange: (change: IFormInputChange) => void;

  className?: string;
  defaultValue?: T;
  hasError?: boolean;
  disabled?: boolean;
  disableClearable?: boolean;
}

export default function SelectInput<T>(props: SelectInputProps<T>) {
  const { options, name, label, defaultValue, hasError, className = "full", getOptionLabel, isOptionEqualToValue, handleInputChange, ...selectProps } = props;

  const { t } = useTranslation();

  let [defaultSelectValue] = useState(defaultValue);

  let { error: isShowingValidationErrors = false } = useFormControl() || {};

  return (
    <Autocomplete
      {...selectProps}
      className={className}
      options={options || []}
      defaultValue={defaultSelectValue}
      noOptionsText={t("common:NoOptions")}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      onChange={(event, newValue) => handleInputChange({ inputName: name, value: newValue })}
      renderInput={(params) => <TextField {...params} fullWidth variant="outlined" label={label} error={isShowingValidationErrors && hasError} />}
      popupIcon={<div className="autocomplete-dropdown-icon"></div>}
    />
  );
}
