import { ComponentType, useCallback, useContext, useEffect, useState } from "react";

import useAuth from "hooks/useAuth";
import { IViewProps } from "models/layout";
import { AppContext } from "utils/context";
import NotificationService from "services/NotificationService";

// HOC for custom data load in all page views
export default function withInitializedData(WrappedComponent: ComponentType<IViewProps>) {
  return function (props: IViewProps) {
    const { token, setError, setToken, setIsLoading, setTriggers, setReceivers, setNotifications } = useContext(AppContext);
    const { getToken } = useAuth();

    const [tokenLoaded, setTokenLoaded] = useState(false);
    const [triggersLoaded, setTriggersLoaded] = useState(false);
    const [receiversLoaded, setReceiversLoaded] = useState(false);
    const [notificationsLoaded, setNotificationsLoaded] = useState(false);

    const loadTriggers = useCallback(
      async (token: string) => {
        try {
          const response = await NotificationService.getTriggers(token);

          if (!response.isSuccess()) {
            throw new Error("Failed to fetch triggers");
          }

          setTriggers(response.data);
        } catch (error) {
          setError(`Failed to fetch triggers: ${error instanceof Error ? error.message : error}`);
        } finally {
          setTriggersLoaded(true);
        }
      },
      [setError, setTriggers]
    );

    const loadReceivers = useCallback(
      async (token: string) => {
        try {
          const response = await NotificationService.getReceivers(token);

          if (!response.isSuccess()) {
            throw new Error();
          }

          setReceivers(response.data);
        } catch (error) {
          setError(`Failed to fetch receivers: ${error instanceof Error ? error.message : error}`);
        } finally {
          setReceiversLoaded(true);
        }
      },
      [setError, setReceivers]
    );

    const loadNotifications = useCallback(
      async (token: string) => {
        try {
          const response = await NotificationService.getNotifications(token);

          if (!response.isSuccess()) {
            throw new Error();
          }

          setNotifications(response.data);
        } catch (error) {
          setError(`Failed to fetch notifications: ${error instanceof Error ? error.message : error}`);
        } finally {
          setNotificationsLoaded(true);
        }
      },
      [setError, setNotifications]
    );

    useEffect(() => {
      if (tokenLoaded && !token) {
        setIsLoading(false);
      } else if (tokenLoaded && triggersLoaded && receiversLoaded) {
        setIsLoading(false);
      }
    }, [tokenLoaded, token, triggersLoaded, receiversLoaded, setIsLoading]);

    useEffect(() => {
      if (token && !triggersLoaded) {
        loadTriggers(token);
      }
    }, [token, triggersLoaded, loadTriggers]);

    useEffect(() => {
      if (token && !receiversLoaded) {
        loadReceivers(token);
      }
    }, [token, receiversLoaded, loadReceivers]);

    useEffect(() => {
      if (token && !notificationsLoaded) {
        loadNotifications(token);
      }
    }, [token, notificationsLoaded, loadNotifications]);

    useEffect(() => {
      if (token) {
        return;
      }

      const storedToken = getToken();

      if (storedToken) {
        setToken(storedToken);
      }

      setTokenLoaded(true);
    }, [token, getToken, setToken, setIsLoading]);

    return <WrappedComponent {...props} />;
  };
}
