import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { IReceiverDto } from "models/receiver";
import { IUpdateDto, UpdatableFields } from "models/resource";
import NotificationService from "services/NotificationService";
import { AppContext } from "utils/context";

export default function useReceiver(receiverId?: string) {
  const { isLoading: appIsLoading, setError, token } = useContext(AppContext);

  const navigate = useNavigate();

  const [receiver, setReceiver] = useState<IReceiverDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const updatableReceiverFields = useMemo(() => {
    const fields: UpdatableFields = new Map();

    if (!receiver) {
      return fields;
    }

    fields.set("name", { labelTranslationKey: "receiver:Name", value: receiver.name });
    fields.set("address", { labelTranslationKey: "receiver:Address", value: receiver.address });

    return fields;
  }, [receiver]);

  const loadReceiver = useCallback(async () => {
    if (appIsLoading || !token || !receiverId) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.getReceiver(token, receiverId);

      if (!response.isSuccess()) {
        throw new Error();
      }

      setReceiver(response.data);
    } catch (error) {
      setError(`Failed to fetch receiver: ${error instanceof Error ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }, [appIsLoading, token, receiverId, setError]);

  const updateReceiver = useCallback(
    async (updatedFields: UpdatableFields) => {
      if (appIsLoading || !token || !receiverId) {
        return;
      }

      const updates = Array.from(updatedFields);

      if (!updates.length) {
        return;
      }

      setIsLoading(true);

      const updateRequests = updates.map<IUpdateDto>((fieldUpdates) => {
        const [key, data] = fieldUpdates;

        return {
          operationType: "Replace",
          path: `/${key}`,
          value: data.value,
        };
      });

      try {
        const response = await NotificationService.updateReceiver(token, receiverId, updateRequests);

        if (!response.isSuccess()) {
          throw new Error();
        }

        loadReceiver();
      } catch (error) {
        setError(`Failed to update receiver: ${error instanceof Error ? error.message : error}`);
        setIsLoading(false);
      }
    },
    [appIsLoading, loadReceiver, receiverId, setError, token]
  );

  const deleteReceiver = useCallback(async () => {
    if (appIsLoading || !token || !receiverId) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.deleteReceiver(token, receiverId);

      if (!response.isSuccess()) {
        throw new Error();
      }

      navigate({ pathname: "/receivers" });
    } catch (error) {
      setError(`Failed to delete receiver: ${error instanceof Error ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }, [appIsLoading, token, navigate, receiverId, setError]);

  const handleAddNotification = useCallback(() => {
    loadReceiver();
  }, [loadReceiver]);

  useEffect(() => {
    loadReceiver();
  }, [loadReceiver]);

  return {
    receiver,
    isLoading,
    updatableReceiverFields,
    updateReceiver,
    deleteReceiver,
    handleAddNotification,
  };
}
