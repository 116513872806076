let environment = "";

if (window.location.href.includes("ci.crunchy.ninja")) {
  environment = "ci.";
} else if (window.location.href.includes("staging.crunchy.ninja")) {
  environment = "staging.";
}

const getEnvironment = () => {
  return environment;
};

export { getEnvironment };
