import { TableRow } from "@crunchit/component-library";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IReceiverDto } from "models/receiver";

import "./ReceiverTableRow.scss";

interface IReceiverTableRowProps {
  receiver: IReceiverDto;
}

export default function ReceiverTableRow({ receiver }: IReceiverTableRowProps) {
  const { id, name, address, receiverType, isActive } = receiver;
  const { t } = useTranslation();

  const typeText = t(`receivers:Type.${receiverType.name}`);
  const isActiveText = t(`common:${isActive ? "Active" : "Inactive"}`);

  return (
    <Link className={`table-row-wrapper ${isActive ? "" : "inactive"}`} to={receiver.id}>
      <TableRow cells={[name, typeText, address, isActiveText]} key={id} />
    </Link>
  );
}
