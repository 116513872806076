import { Button } from "@crunchit/component-library";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { TextInput } from "components/ui/Form";
import { UpdatableField, UpdatableFields } from "models/resource";

import "./DetailsInformation.scss";

interface IDetailsInformation {
  fields: UpdatableFields;
  handleFieldsUpdated: (newFields: UpdatableFields) => void;
}

export default function DetailsInformation({ fields, handleFieldsUpdated }: IDetailsInformation) {
  const { t } = useTranslation();

  const [updatedFields, setUpdatedFields] = useState<UpdatableFields>(new Map());
  const [isEditing, setIsEditing] = useState(false);

  const onInputChange = useCallback(
    (key: string, newData: UpdatableField) => {
      const newUpdatedFields = new Map(updatedFields);
      newUpdatedFields.set(key, newData);
      setUpdatedFields(newUpdatedFields);
    },
    [updatedFields]
  );

  const handleSaveChanges = useCallback(() => {
    handleFieldsUpdated(updatedFields);
    setIsEditing(false);
  }, [handleFieldsUpdated, updatedFields]);

  return (
    <section className="details-information">
      <h1>{t("common:Information")}</h1>

      <form>
        {Array.from(fields).map((field) => {
          const [key, data] = field;
          const { value } = data;

          return (
            <div className="form-group" key={key}>
              {data.labelTranslationKey && <label>{t(data.labelTranslationKey)}</label>}
              <TextInput defaultValue={value} handleInputChange={({ value: newValue }) => onInputChange(key, { value: newValue })} disabled={!isEditing} />
            </div>
          );
        })}

        <footer>
          {isEditing ? (
            <Button attributes={{ type: "button" }} onClick={handleSaveChanges}>
              {t("common:SaveChanges")}
            </Button>
          ) : (
            <Button attributes={{ type: "button" }} onClick={() => setIsEditing(true)}>
              {t("common:Edit")}
            </Button>
          )}
        </footer>
      </form>
    </section>
  );
}
