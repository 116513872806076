import common from "./common.json";
import errors from "./errors.json";
import login from "./login.json";
import pagetitles from "./pagetitles.json";
import navigation from "./navigation.json";
import receivers from "./receivers.json";
import notifications from "./notifications.json";
import logs from "./logs.json";

export const da = {
  common,
  errors,
  login,
  pagetitles,
  navigation,
  receivers,
  notifications,
  logs,
};
