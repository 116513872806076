import { FloatingButton, Icon } from "@crunchit/component-library";
import { Link, To } from "react-router-dom";

import "./CreateButtonLink.scss";

interface ICreateButtonLinkProps {
  to: To;
  text: string;
}

export default function CreateButtonLink({ to, text }: ICreateButtonLinkProps) {
  return (
    <Link to={to} className="create-button-link">
      <FloatingButton>
        <>
          <Icon height={16} width={16} url="https://crunchorderfrontend.blob.core.windows.net/icons/plus.svg" />
          <span>{text}</span>
        </>
      </FloatingButton>
    </Link>
  );
}
