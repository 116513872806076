import { Route, Routes } from "react-router-dom";

import Create from "./Create";
import Notifications from "./Notifications";
import Notification from "./Notification";

import "./Root.scss";

export default function Root() {
  return (
    <Routes>
      <Route path="" Component={Notifications} />
      <Route path="create" Component={Create} />
      <Route path=":id" Component={Notification} />
    </Routes>
  );
}
