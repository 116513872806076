import { TableRow } from "@crunchit/component-library";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { INotificationDto } from "models/notification";

import "./NotificationTableRow.scss";

interface INotificationTableRowProps {
  notification: INotificationDto;
}

export default function NotificationTableRow({ notification }: INotificationTableRowProps) {
  const { trigger, isActive, receivers = [] } = notification;
  const { t } = useTranslation();

  const isActiveText = t(`common:${isActive ? "Active" : "Inactive"}`);
  const receiverItems = receivers.map<JSX.Element>((receiver) => {
    return (
      <span className="receiver" key={receiver.id}>
        {receiver.name}
      </span>
    );
  });
  const receiverList = <ul className="receivers">{receiverItems}</ul>;

  return (
    <Link className={`table-row-wrapper ${isActive ? "" : "inactive"}`} to={notification.id}>
      <TableRow cells={[notification.name, trigger.name, isActiveText, receiverList]} />
    </Link>
  );
}
