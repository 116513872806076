import { del, get, patch, post } from "@crunchit/http-client";
import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { IUpdateDto } from "models/resource";
import { ITriggerDto } from "models/trigger";
import { getEnvironment } from "utils/helpers/service";

const api = process.env.REACT_APP_NOTIFICATION_URI || `notification.${getEnvironment()}crunchy.ninja/api`;

const NotificationService = {
  getNotifications: async (token: string) => {
    return get<INotificationDto[]>(`${api}/notification`, token);
  },
  getNotification: async (token: string, id: string) => {
    return get<INotificationDto>(`${api}/notification/${id}`, token);
  },
  deleteNotification: async (token: string, id: string) => {
    return del<INotificationDto>(`${api}/notification/${id}`, token);
  },
  addReceiverToNotification: async (token: string, notificationId: string, receiverId: string) => {
    return post<INotificationDto>(`${api}/notification/${notificationId}/receiver/${receiverId}`, {}, token);
  },
  addNotificationToReceiver: async (token: string, receiverId: string, notificationId: string) => {
    return post<INotificationDto>(`${api}/receiver/${receiverId}/notification/${notificationId}`, {}, token);
  },
  updateNotification: async (token: string, id: string, updates: IUpdateDto[]) => {
    return patch<INotificationDto>(`${api}/notification/${id}`, updates, token);
  },
  getReceiver: async (token: string, id: string) => {
    return get<IReceiverDto>(`${api}/receiver/${id}`, token);
  },
  getReceivers: async (token: string) => {
    return get<IReceiverDto[]>(`${api}/receiver`, token);
  },
  createReceiver: async (token: string, receiverTypeId: string, name: string, address: string, isActive = true) => {
    return post<IReceiverDto>(`${api}/receiver`, { name, receiverTypeId, address, isActive }, token);
  },
  updateReceiver: async (token: string, id: string, updates: IUpdateDto[]) => {
    return patch<IReceiverDto>(`${api}/receiver/${id}`, updates, token);
  },
  deleteReceiver: async (token: string, id: string) => {
    return del<IReceiverDto>(`${api}/receiver/${id}`, token);
  },
  getTriggers: async (token: string) => {
    return get<ITriggerDto[]>(`${api}/trigger`, token);
  },
  createNotification: async (token: string, triggerId: string, name: string) => {
    return post<INotificationDto>(`${api}/notification`, { triggerId, name }, token);
  },
  deleteReceiverFromNotification: async (notificationId: string, receiverId: string, token: string) => {
    return del<INotificationDto>(`${api}/notification/${notificationId}/receiver/${receiverId}`, token);
  },
};

export default NotificationService;
