import { Button, Card } from "@crunchit/component-library";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Form, { FormSection, TextInput } from "components/ui/Form";
import GoBackLink from "components/ui/links/GoBackLink";
import TriggerSelect from "components/ui/TriggerSelect";
import { ITriggerDto } from "models/trigger";
import NotificationService from "services/NotificationService";
import { AppContext } from "utils/context";

export default function Create() {
  const { isLoading, setIsLoading, token } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [trigger, setTrigger] = useState<ITriggerDto | null>(null);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [triggerError, setTriggerError] = useState("");
  const [message, setMessage] = useState("");

  const reset = useCallback(() => {
    setIsLoading(false);

    setName("");
    setTrigger(null);
    setNameError("");
    setTriggerError("");
  }, [setIsLoading]);

  const validate = useCallback(
    (name: string, trigger: ITriggerDto | null) => {
      const nameIsValid = name.length > 0;
      const triggerIsValid = trigger !== null;

      if (!nameIsValid) {
        setNameError(t("errors:Input.Name"));
      }

      if (!triggerIsValid) {
        setTriggerError(t("errors:Input.Trigger"));
      }

      return nameIsValid && triggerIsValid;
    },
    [t]
  );

  const onSubmit = useCallback(async () => {
    setShowValidationErrors(false);
    setHasError(false);

    if (isLoading || !token) {
      return;
    }

    const isValid = validate(name, trigger);

    if (!isValid) {
      setShowValidationErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.createNotification(token, trigger?.id || "", name);

      if (!response.isSuccess()) {
        throw new Error("Failed to create new notification");
      }

      navigate(`/notifications/${response.data.id}`);
    } catch (error) {
      setHasError(true);
      setMessage(t("errors:Error"));
    } finally {
      reset();
    }
  }, [isLoading, token, validate, name, trigger, reset, setIsLoading, t, navigate]);

  useEffect(() => setNameError(""), [name]);
  useEffect(() => setTriggerError(""), [trigger]);

  return (
    <Card className="card create-card">
      <>
        <GoBackLink to="/notifications" text={t("notifications:Create.GoBack")} />

        <div className="card-content">
          <h1 className="page-heading">{t("notifications:Create.Title")}</h1>

          <Form isShowingValidationErrors={showValidationErrors}>
            <FormSection>
              <div className="trigger-select">
                <TriggerSelect currentTrigger={trigger} label={t("notifications:Create.Form.Trigger")} hasError={triggerError.length > 0} onSelect={(t) => setTrigger(t)} />
                {triggerError && <p className="error">{triggerError}</p>}
              </div>

              <TextInput required name="name" value={name} label={t("notifications:Create.Form.Name")} type="text" error={nameError.length > 0} helperText={nameError} handleInputChange={({ value }) => setName(value)} />

              {message.length > 0 && <p className={hasError ? "error" : ""}>{message}</p>}

              <div className="form-actions">
                <Button attributes={{ type: "submit" }} onClick={onSubmit}>
                  {t("notifications:Create.Form.Actions.Confirm")}
                </Button>

                <Link to="/notifications">
                  <Button theme="tertiary" attributes={{ type: "button" }}>
                    {t("notifications:Create.Form.Actions.Cancel")}
                  </Button>
                </Link>
              </div>
            </FormSection>
          </Form>
        </div>
      </>
    </Card>
  );
}
