import { Button, Card, Spinner } from "@crunchit/component-library";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import DetailsHeader from "components/details/DetailsHeader";
import DetailsInformation from "components/details/DetailsInformation";
import DetailsStatus from "components/details/DetailsStatus";
import useNotification from "hooks/useNotification";
import { UpdatableFields } from "models/resource";
import { AppContext } from "utils/context";
import AddReceiver from "components/receiver/AddReceiver";

export default function ViewNotification() {
  const { isLoading: appIsLoading } = useContext(AppContext);
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, notification, updatableNotificationFields, updateNotification, deleteNotification, handleAddReceiver, handleRemoveReceiverFromNotification } = useNotification(id);

  const handleUpdateIsActive = (newIsActive: string) => {
    const updateMap: UpdatableFields = new Map();
    updateMap.set("isActive", { value: newIsActive });
    updateNotification(updateMap);
  };

  if (appIsLoading) {
    return <></>;
  }

  return (
    <Card className="card details-card">
      <>
        {isLoading && <Spinner className="spinner" />}

        {notification && (
          <>
            <DetailsHeader title={notification.name} closeTo={{ pathname: "/notifications" }} handleDelete={deleteNotification} />

            <div className="card-content">
              <DetailsStatus isActive={notification.isActive} handleUpdate={(newIsActive) => handleUpdateIsActive(`${newIsActive}`)} />

              <section>
                <h1>{t("notifications:TriggerName")}</h1>
                <p>{notification.trigger.name}</p>
              </section>

              <DetailsInformation fields={updatableNotificationFields} handleFieldsUpdated={updateNotification} />

              <section className="details-list">
                <h1>{t("notifications:Receivers")}</h1>

                <ul className="notifications-receiver-list">
                  <li className="list-header">
                    <span>{t("notifications:Name")}</span>
                    <span></span>
                  </li>

                  {notification.receivers?.map((receiver, i) => (
                    <li className="list-item" key={i}>
                      <Link className="receiver-link" to={`/receivers/${receiver.id}`}>
                        {receiver.name}
                      </Link>

                      <Button className="remove-button" theme="danger-secondary" onClick={() => handleRemoveReceiverFromNotification(receiver.id)}>
                        {t("common:Remove")}
                      </Button>
                    </li>
                  ))}
                </ul>

                <AddReceiver notification={notification} onAddSuccess={handleAddReceiver} />
              </section>
            </div>
          </>
        )}
      </>
    </Card>
  );
}
