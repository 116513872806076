import { ReactNode } from "react";

import { FormControl } from "@mui/material";

import "./Form.scss";

export default function Form({ title, isShowingValidationErrors, children }: { title?: string; isShowingValidationErrors?: boolean; children: ReactNode | ReactNode[] }) {
  return (
    <FormControl fullWidth error={!!isShowingValidationErrors}>
      <div className="form">{children}</div>
    </FormControl>
  );
}
