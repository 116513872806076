import { Table, TableRow } from "@crunchit/component-library";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import NotificationTableRow from "components/table/notification/NotificationTableRow";
import CreateButtonLink from "components/ui/links/CreateButtonLink";
import ReceiverSelect from "components/ui/ReceiverSelect";
import TriggerSelect from "components/ui/TriggerSelect";
import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { ITriggerDto } from "models/trigger";
import NotificationService from "services/NotificationService";
import { AppContext } from "utils/context";

export default function ViewNotifications() {
  const { isLoading, setIsLoading, setError, token, notifications, setNotifications } = useContext(AppContext);
  const { t } = useTranslation();

  const [viewNotifications, setViewNotifications] = useState<INotificationDto[]>([]);
  const [triggerFilter, setTriggerFilter] = useState<ITriggerDto | null>(null);
  const [receiverFilter, setReceiverFilter] = useState<IReceiverDto | null>(null);
  const [notificationsLoaded, setNotificationsLoaded] = useState(false);

  const noResults = useMemo(() => !isLoading && notificationsLoaded && viewNotifications.length === 0, [isLoading, notificationsLoaded, viewNotifications]);

  const loadNotifications = useCallback(
    async (token: string) => {
      setIsLoading(true);

      try {
        const response = await NotificationService.getNotifications(token);

        if (!response.isSuccess()) {
          throw new Error();
        }

        const sortedNotifications = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setNotifications(sortedNotifications);
      } catch (error) {
        setError(`Failed to fetch notifications: ${error instanceof Error ? error.message : error}`);
      } finally {
        setNotificationsLoaded(true);
        setIsLoading(false);
      }
    },
    [setError, setIsLoading, setNotifications]
  );

  useEffect(() => {
    let newViewNotifications = notifications;

    if (triggerFilter) {
      newViewNotifications = newViewNotifications.filter((n) => n.trigger.id === triggerFilter.id);
    }

    if (receiverFilter) {
      newViewNotifications = newViewNotifications.filter((n) => n.receivers && n.receivers.some((r) => r.id === receiverFilter.id));
    }

    setViewNotifications(newViewNotifications);
  }, [notifications, triggerFilter, receiverFilter]);

  useEffect(() => {
    if (!isLoading && token && !notificationsLoaded) {
      loadNotifications(token);
    }
  }, [isLoading, token, notificationsLoaded, loadNotifications]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="notifications-list">
      <div className="filters">
        <TriggerSelect currentTrigger={triggerFilter} label={t("notifications:TriggerFilter.Label")} onSelect={(t) => setTriggerFilter(t)} />
        <ReceiverSelect currentReceiver={receiverFilter} label={t("notifications:ReceiverFilter.Label")} onSelect={(r) => setReceiverFilter(r)} />
      </div>

      <Table
        columns={[
          { header: t("notifications:Name"), width: "0.4fr" },
          { header: t("notifications:TriggerName"), width: "0.4fr" },
          { header: t("common:IsActive"), width: "0.1fr" },
          { header: t("notifications:Receivers"), width: "0.5fr" },
        ]}
      >
        <>
          {viewNotifications.map((notification, i) => (
            <NotificationTableRow notification={notification} key={i} />
          ))}

          {noResults && <TableRow cells={[<p>{t("common:NoResults")}</p>]} />}
        </>
      </Table>

      <CreateButtonLink to={"create"} text={t("notifications:NewNotification")} />
    </div>
  );
}
