import { Icon } from "@crunchit/component-library";
import { Link, To } from "react-router-dom";

import "./CloseLink.scss";

interface ICloseLinkProps {
  to: To;
}

export default function CloseLink({ to }: ICloseLinkProps) {
  return (
    <Link to={to} className="close-link">
      <Icon height={24} width={24} url="https://crunchorderfrontend.blob.core.windows.net/icons/close.svg" />
    </Link>
  );
}
