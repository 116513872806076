import React from "react";
import "./Input.scss";

interface IInputProps {
  inputValue: string;
  label?: string;

  errorMessage?: string;

  overrideParameters: {
    name: string;
    type: string;
    placeholder?: string;
    disabled?: boolean;
  };

  inputChanged: (update: { value: string; name: string }) => void;
}

export default function Input(props: IInputProps) {
  const { inputValue, label = "", errorMessage = "", overrideParameters } = props;
  const { inputChanged } = props;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value, name } = event.target;
    inputChanged({ value, name });
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value, name } = event.target as HTMLInputElement;
    if (event.keyCode === 13) {
      inputChanged({ value, name });
    }
  };

  return (
    <div className="input-item">
      <div className="floating-label">
        {label.length > 0 && <label className={`input-label ${inputValue ? "active" : ""}`}>{label}</label>}

        <input value={inputValue} autoComplete="off" {...overrideParameters} onChange={handleInputChange} onKeyUp={handleKeyUp} />
      </div>

      {errorMessage.length > 0 && <div className="input-error">{errorMessage}</div>}
    </div>
  );
}
