import { useEffect, useMemo, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router";

import withInitializedData from "./hoc/withInitializedData";
import routes from "routes";
import { toggleNoScroll } from "utils/helpers/view";
import NotFound from "./views/NotFound";
import View from "./views/View";
import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { ITriggerDto } from "models/trigger";
import { AppContext } from "utils/context";

export default function App() {
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const [triggers, setTriggers] = useState<ITriggerDto[]>([]);
  const [receivers, setReceivers] = useState<IReceiverDto[]>([]);
  const [notifications, setNotifications] = useState<INotificationDto[]>([]);

  const contextValue = useMemo(() => ({ isLoading, setIsLoading, token, setToken, error, setError, triggers, setTriggers, receivers, setReceivers, notifications, setNotifications }), [isLoading, token, error, setError, triggers, receivers, notifications]);

  // Reloads the page if a mobile browser pressed back and is serving from cache
  useEffect(() => {
    window.onpageshow = function (event) {
      const performanceType = window.performance && window.performance.navigation && window.performance.navigation.type ? window.performance.navigation.type : undefined;

      // eslint-disable-next-line
      if (event.persisted || performanceType == 2) {
        window.location.reload();
      }
    };
  }, []);

  // Handle scroll when a new page is loaded
  useEffect(() => {
    toggleNoScroll(false);
    window.scrollTo(0, 0);
  }, [location]);

  // Wrapper component - ensures data is fetched before rendering the main component
  let ViewComponent = useMemo(() => withInitializedData(View), []);

  return (
    <AppContext.Provider value={contextValue}>
      <Routes>
        {routes.map(({ key, path, pageTitleKey, Component }) => {
          return (
            <Route
              key={key}
              path={path}
              element={
                <ViewComponent pageKey={key} pageTitleKey={pageTitleKey}>
                  <Component />
                </ViewComponent>
              }
            />
          );
        })}

        <Route path={"/notfound"} element={<NotFound />} />
        <Route path="*" element={<Navigate replace to="/notfound" />} />
      </Routes>
    </AppContext.Provider>
  );
}
