import { Button } from "@crunchit/component-library";
import { t } from "i18next";
import { useCallback, useContext, useMemo, useState } from "react";

import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { AppContext } from "utils/context";
import NotificationService from "services/NotificationService";
import NotificationSelect from "components/ui/NotificationSelect";

interface IAddNotificationProps {
  receiver: IReceiverDto;
  onAddSuccess: (receiver: INotificationDto) => void;
}

export default function AddNotification({ receiver, onAddSuccess }: IAddNotificationProps) {
  const { token, notifications, setIsLoading, setError } = useContext(AppContext);

  const [notificationToAdd, setNotificationToAdd] = useState<INotificationDto | null>(null);
  const [showAddNotification, setShowAddNotification] = useState(false);

  const notificationOptions = useMemo(() => {
    if (!receiver.notifications) {
      return notifications;
    }

    const receiverNotificationIds = receiver.notifications.map((n) => n.id);

    return notifications.filter((n) => !receiverNotificationIds.includes(n.id));
  }, [receiver, notifications]);

  const addNotificationToReceiver = useCallback(async () => {
    if (!notificationToAdd || !token) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.addNotificationToReceiver(token, receiver.id, notificationToAdd.id);

      if (!response.isSuccess()) {
        throw new Error();
      }

      onAddSuccess(notificationToAdd);
    } catch (error) {
      setError(`Failed to add receiver with id '${notificationToAdd.id}' to receiver with id '${receiver.id}': ${error instanceof Error ? error.message : error}`);
    } finally {
      setNotificationToAdd(null);
      setShowAddNotification(false);
      setIsLoading(false);
    }
  }, [notificationToAdd, token, receiver.id, onAddSuccess, setError, setIsLoading]);

  return (
    <div className="add-to-list">
      {showAddNotification ? (
        <>
          <NotificationSelect currentNotification={notificationToAdd} label={t("receivers:View.ChooseNotification")} onSelect={setNotificationToAdd} overrideOptions={notificationOptions} />
          <Button attributes={{ disabled: notificationToAdd === null }} onClick={addNotificationToReceiver}>
            {t("receivers:View.Add")}
          </Button>
        </>
      ) : (
        <Button className="add-to-list-button" theme="tertiary" onClick={() => setShowAddNotification(true)}>
          {t("receivers:View.AddNotification")}
        </Button>
      )}
    </div>
  );
}
