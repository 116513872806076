import { Table } from "@crunchit/component-library";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ReceiverTableRow from "components/table/receiver/ReceiverTableRow";
import CreateButtonLink from "components/ui/links/CreateButtonLink";
import { AppContext } from "utils/context";
import NotificationService from "services/NotificationService";

export default function Receivers() {
  const { isLoading, setIsLoading, receivers, setReceivers, token, setError } = useContext(AppContext);
  const { t } = useTranslation();

  const [receiversLoaded, setReceiversLoaded] = useState(false);

  const loadReceivers = useCallback(
    async (token: string) => {
      setIsLoading(true);

      try {
        const response = await NotificationService.getReceivers(token);

        if (!response.isSuccess()) {
          throw new Error();
        }

        const sortedReceivers = response.data.sort((a, b) => a.name.localeCompare(b.name));
        setReceivers(sortedReceivers);
      } catch (error) {
        setError(`Failed to fetch receivers: ${error instanceof Error ? error.message : error}`);
      } finally {
        setReceiversLoaded(true);
        setIsLoading(false);
      }
    },
    [setError, setIsLoading, setReceivers]
  );

  useEffect(() => {
    if (!isLoading && token && !receiversLoaded) {
      loadReceivers(token);
    }
  }, [isLoading, token, receiversLoaded, loadReceivers]);

  if (isLoading) {
    return <></>;
  }

  return (
    <div className="receivers-list">
      <Table columns={[{ header: t("receivers:Name"), width: "0.5fr" }, { header: t("receivers:Type.Label"), width: "0.25fr" }, { header: t("receivers:Address") }, { header: t("receivers:IsActive.Label"), width: "0.25fr" }]}>
        {receivers.map((receiver, i) => (
          <ReceiverTableRow receiver={receiver} key={i} />
        ))}
      </Table>

      <CreateButtonLink to={"create"} text={t("receivers:NewReceiver")} />
    </div>
  );
}
