import { Card, Spinner } from "@crunchit/component-library";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";

import DetailsHeader from "components/details/DetailsHeader";
import DetailsInformation from "components/details/DetailsInformation";
import DetailsStatus from "components/details/DetailsStatus";
import useReceiver from "hooks/useReceiver";
import { UpdatableFields } from "models/resource";
import { AppContext } from "utils/context";
import AddNotification from "components/notification/AddNotification";

export default function Receiver() {
  const { isLoading: appIsLoading } = useContext(AppContext);
  const { id } = useParams();
  const { t } = useTranslation();

  const { isLoading, receiver, updatableReceiverFields, updateReceiver, deleteReceiver, handleAddNotification } = useReceiver(id);

  const handleUpdateIsActive = (newIsActive: string) => {
    const updateMap: UpdatableFields = new Map();
    updateMap.set("isActive", { value: newIsActive });
    updateReceiver(updateMap);
  };

  if (appIsLoading) {
    return <></>;
  }

  return (
    <Card className="card details-card">
      <>
        {isLoading && <Spinner className="spinner" />}

        {receiver && (
          <>
            <DetailsHeader title={receiver.name} closeTo={{ pathname: "/receivers" }} handleDelete={deleteReceiver} />

            <div className="card-content">
              <DetailsStatus isActive={receiver.isActive} handleUpdate={(newIsActive) => handleUpdateIsActive(`${newIsActive}`)} />

              <DetailsInformation fields={updatableReceiverFields} handleFieldsUpdated={updateReceiver} />

              <section className="details-list">
                <h1>{t("receivers:View.Notifications")}</h1>

                <ul className="receiver-notifications-list">
                  <li className="list-header">
                    <span>{t("receivers:View.Name")}</span>
                  </li>

                  {receiver.notifications?.map((notification, i) => (
                    <li className="list-item" key={i}>
                      <Link className="notification-link" to={`/notifications/${notification.id}`}>
                        {notification.name}
                      </Link>
                    </li>
                  ))}
                </ul>

                <AddNotification receiver={receiver} onAddSuccess={handleAddNotification} />
              </section>
            </div>
          </>
        )}
      </>
    </Card>
  );
}
