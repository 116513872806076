import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "normalize.css/normalize.css";

import { Spinner } from "@crunchit/component-library";
import { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "@crunchit/component-library/dist/app.css";
import "assets/styles/index.scss";

import "i18n";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import { globalTheme, globalComponentsTheme } from "./utils/muiStyles";

import App from "./App";
import ErrorBoundary from "./views/ErrorBoundary";

let theme = createTheme(globalTheme);
theme = createTheme(theme, globalComponentsTheme);

const container = document.getElementById("root")!;
const root = createRoot(container);

root.render(
  <ErrorBoundary>
    <Suspense fallback={<Spinner />}>
      <BrowserRouter>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </Suspense>
  </ErrorBoundary>
);
