import { Spinner } from "@crunchit/component-library";
import { useContext, useMemo } from "react";

import Header from "components/header/Header";
import useAppRedirects from "hooks/useAppRedirects";
import { IViewProps } from "models/layout";
import { AppContext } from "utils/context";

// Performing all page checks necessary before loading content
export default function View(props: IViewProps) {
  const { pageKey, pageTitleKey, children } = props;

  const { isLoading, token, error } = useContext(AppContext);

  const isError = useMemo(() => error !== null && error.length > 0, [error]);
  const isAuthenticated = useMemo(() => token !== null && token.length > 0, [token]);

  useAppRedirects({ isError, isLoading, isAuthenticated });

  return (
    <div className="page-view">
      {isAuthenticated && <Header pageTitleKey={pageTitleKey} />}

      <main className={`page ${pageKey}-page`}>{children}</main>

      {isLoading && <Spinner />}
    </div>
  );
}
