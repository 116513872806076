import { Button, Card } from "@crunchit/component-library";
import { useCallback, useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

import Form, { FormSection, RadioOptions, TextInput } from "components/ui/Form";
import GoBackLink from "components/ui/links/GoBackLink";
import NotificationService from "services/NotificationService";
import { AppContext } from "utils/context";

enum TypeOption {
  EMAIL = "Email",
  SMS = "Sms",
  PRINT = "Print",
}

export default function Create() {
  const { isLoading, setIsLoading, token } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [showValidationErrors, setShowValidationErrors] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [type, setType] = useState<string>(TypeOption.EMAIL);
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [nameError, setNameError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [message, setMessage] = useState("");

  const reset = useCallback(() => {
    setIsLoading(false);

    setName("");
    setAddress("");
    setNameError("");
    setAddressError("");
  }, [setIsLoading]);

  const validate = useCallback(
    (name: string, address: string) => {
      const nameIsValid = name.length > 0;
      const addressIsValid = address.length > 0;

      if (!nameIsValid) {
        setNameError(t("errors:Input.Name"));
      }

      if (!addressIsValid) {
        setAddressError(t(`errors:Input.${type}`));
      }

      return nameIsValid && addressIsValid;
    },
    [type, t]
  );

  const onSubmit = useCallback(async () => {
    setShowValidationErrors(false);
    setHasError(false);

    if (isLoading || !token) {
      return;
    }

    const isValid = validate(name, address);

    if (!isValid) {
      setShowValidationErrors(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.createReceiver(token, type, name, address);

      if (!response.isSuccess()) {
        throw new Error("Failed to create new receiver");
      }

      navigate(`/receivers/${response.data.id}`);
    } catch (error) {
      setHasError(true);
      setMessage(t("errors:Error"));
    } finally {
      reset();
    }
  }, [isLoading, token, validate, name, address, type, reset, setIsLoading, t, navigate]);

  useEffect(() => setNameError(""), [name]);
  useEffect(() => setAddressError(""), [address]);

  return (
    <Card className="card create-card">
      <>
        <GoBackLink to="/receivers" text={t("receivers:Create.GoBack")} />

        <div className="card-content">
          <h1 className="page-heading">{t("receivers:Create.Title")}</h1>

          <Form isShowingValidationErrors={showValidationErrors}>
            <FormSection>
              <RadioOptions row value={type} options={[TypeOption.EMAIL, TypeOption.SMS, TypeOption.PRINT]} getOptionLabel={(option) => t(`receivers:Create.Form.TypeOptions.${option}`) as string} handleInputChange={({ value }) => setType(value)} />

              <TextInput required name="name" value={name} label={t("receivers:Create.Form.Name")} type="text" error={nameError.length > 0} helperText={nameError} handleInputChange={({ value }) => setName(value)} />
              <TextInput required name="address" value={address} label={t(`receivers:Create.Form.TypeOptions.${type}`)} type="text" error={addressError.length > 0} helperText={addressError} handleInputChange={({ value }) => setAddress(value)} />

              {message.length > 0 && <p className={hasError ? "error" : ""}>{message}</p>}

              <div className="form-actions">
                <Button attributes={{ type: "submit" }} onClick={onSubmit}>
                  {t("receivers:Create.Form.Actions.Confirm")}
                </Button>

                <Link to="/receivers">
                  <Button theme="tertiary" attributes={{ type: "button" }}>
                    {t("receivers:Create.Form.Actions.Cancel")}
                  </Button>
                </Link>
              </div>
            </FormSection>
          </Form>
        </div>
      </>
    </Card>
  );
}
