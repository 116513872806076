import { Button } from "@crunchit/component-library";
import { t } from "i18next";
import { useCallback, useContext, useMemo, useState } from "react";

import ReceiverSelect from "components/ui/ReceiverSelect";
import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { AppContext } from "utils/context";
import NotificationService from "services/NotificationService";

interface IAddReceiverProps {
  notification: INotificationDto;
  onAddSuccess: (receiver: IReceiverDto) => void;
}

export default function AddReceiver({ notification, onAddSuccess }: IAddReceiverProps) {
  const { token, receivers, setIsLoading, setError } = useContext(AppContext);

  const [receiverToAdd, setReceiverToAdd] = useState<IReceiverDto | null>(null);
  const [showAddReceiver, setShowAddReceiver] = useState(false);

  const receiverOptions = useMemo(() => {
    if (!notification.receivers) {
      return receivers;
    }

    const notificatinoReceiverIds = notification.receivers.map((r) => r.id);

    return receivers.filter((r) => !notificatinoReceiverIds.includes(r.id));
  }, [receivers, notification]);

  const addReceiverToNotification = useCallback(async () => {
    if (!receiverToAdd || !token) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.addReceiverToNotification(token, notification.id, receiverToAdd.id);

      if (!response.isSuccess()) {
        throw new Error();
      }

      onAddSuccess(receiverToAdd);
    } catch (error) {
      setError(`Failed to add receiver with id '${receiverToAdd.id}' to notification with id '${notification.id}': ${error instanceof Error ? error.message : error}`);
    } finally {
      setReceiverToAdd(null);
      setShowAddReceiver(false);
      setIsLoading(false);
    }
  }, [receiverToAdd, token, notification.id, onAddSuccess, setError, setIsLoading]);

  return (
    <div className="add-to-list">
      {showAddReceiver ? (
        <>
          <ReceiverSelect currentReceiver={receiverToAdd} label={t("notifications:View.ChooseReceiver")} onSelect={setReceiverToAdd} overrideOptions={receiverOptions} />
          <Button attributes={{ disabled: receiverToAdd === null }} onClick={addReceiverToNotification}>
            {t("notifications:View.Add")}
          </Button>
        </>
      ) : (
        <Button className="add-to-list-button" theme="tertiary" onClick={() => setShowAddReceiver(true)}>
          {t("notifications:View.AddReceiver")}
        </Button>
      )}
    </div>
  );
}
