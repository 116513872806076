import useAuth from "hooks/useAuth";
import { useEffect } from "react";

export default function Logout() {
  const { signOut } = useAuth();

  useEffect(signOut, [signOut]);

  return <></>;
}
