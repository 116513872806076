import ErrorPage from "views/ErrorPage";
import Receivers from "views/receivers/Root";
import Notifications from "views/notifications/Root";
import Login from "views/Login";
import Logout from "views/Logout";
import RootNavigator from "components/routes/RootNavigator";

const routes = [
  {
    path: "/",
    key: "root",
    pageTitleKey: "",
    Component: RootNavigator,
  },
  {
    path: "/receivers/*",
    key: "receivers",
    pageTitleKey: "ReceiversPage",
    Component: Receivers,
  },
  {
    path: "/notifications/*",
    key: "notifications",
    pageTitleKey: "NotificationsPage",
    Component: Notifications,
  },
  {
    path: "/login",
    key: "login",
    pageTitleKey: "",
    Component: Login,
  },
  {
    path: "/logout",
    key: "logout",
    pageTitleKey: "",
    Component: Logout,
  },
  {
    path: "/error",
    key: "error",
    pageTitleKey: "ErrorPage",
    Component: ErrorPage,
  },
];

export default routes;
