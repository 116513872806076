import { INotificationDto } from "models/notification";
import { IReceiverDto } from "models/receiver";
import { ITriggerDto } from "models/trigger";
import { Dispatch, SetStateAction, createContext } from "react";

export type AppContextType = {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  token: string | null;
  setToken: Dispatch<SetStateAction<string | null>>;
  error: string | null;
  setError: Dispatch<SetStateAction<string | null>>;
  triggers: ITriggerDto[];
  setTriggers: Dispatch<SetStateAction<ITriggerDto[]>>;
  receivers: IReceiverDto[];
  setReceivers: Dispatch<SetStateAction<IReceiverDto[]>>;
  notifications: INotificationDto[];
  setNotifications: Dispatch<SetStateAction<INotificationDto[]>>;
};

const defaultContext: AppContextType = {
  isLoading: true,
  setIsLoading: (): void => {
    throw new Error("setIsLoading function must be overridden");
  },
  token: null,
  setToken: (): void => {
    throw new Error("setToken function must be overridden");
  },
  error: null,
  setError: (): void => {
    throw new Error("setError function must be overridden");
  },
  triggers: [],
  setTriggers: (): void => {
    throw new Error("setTriggers function must be overridden");
  },
  receivers: [],
  setReceivers: (): void => {
    throw new Error("setReceivers function must be overridden");
  },
  notifications: [],
  setNotifications: (): void => {
    throw new Error("setNotifications function must be overridden");
  },
};

export const AppContext = createContext<AppContextType>(defaultContext);
