// Styling objects for Material UI components

export const globalTheme = {
  palette: {
    primary: {
      main: "#0051ff",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
};

export const globalComponentsTheme = {
  components: {
    MuiFab: {
      styleOverrides: {
        root: {
          backgroundColor: "#0051ff",
          "&:hover": {
            backgroundColor: "#163052",
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          width: "100%",
        },
      },
    },
  },
};

export const textFieldStyles = {
  root: {
    borderColor: "#dcdada",
    width: "100%",

    "& label.Mui-focused": {
      color: "#303030",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dcdada",
      },
      "&:hover fieldset": {
        borderColor: "#dcdada",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#dcdada",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#dcdada",
      },
    },
  },
};

export const fabStyles = {
  root: {
    backgroundColor: "#6B75FA",
    "&:hover": {
      backgroundColor: "#163052",
    },
  },
};

export const tabStyles = {
  root: {
    "& button.Mui-selected": {
      color: "#000",
      fontFamily: "GothamRnd",
    },
    "& .MuiTabs-indicator": {
      backgroundColor: "#163052",
    },
  },
};

export const switchStyles = {
  switchBase: {
    "&$checked": {
      color: "#6B75FA",

      "&:hover": {
        backgroundColor: "rgba(107, 117, 250, 0.04)",
      },
    },
    "&$checked + $track": {
      backgroundColor: "#6B75FA",
    },
  },
  checked: {},
  track: {},
};

export const radioStyles = {
  root: {
    color: "#292e42",
    "&$checked": {
      color: "#292e42",
    },
    "&:hover": {
      backgroundColor: "rgba(107, 117, 250, 0.04)",
    },
  },
  checked: {
    color: "#292e42",
  },
};

export const checkboxStyles = {
  root: {
    color: "#292e42",
    "&$checked": {
      color: "#292e42",
    },
    "&:hover": {
      backgroundColor: "rgba(107, 117, 250, 0.04)",
    },
  },
  checked: {
    color: "#292e42",
  },
};

export const autoCompleteStyles = {
  root: {
    borderColor: "#dcdada",
    width: "100%",

    "& label.Mui-focused": {
      color: "#303030",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#dcdada",
      },
      "&:hover fieldset": {
        borderColor: "#dcdada",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#dcdada",
      },
      "&.Mui-disabled fieldset": {
        borderColor: "#dcdada",
      },
    },
  },
};
