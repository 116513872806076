import { ReactNode } from "react";

import "./Section.scss";

export default function FormSection({ title, className = "", noBorder, children }: { title?: string; className?: string; noBorder?: boolean; children: ReactNode[] | ReactNode }) {
  return (
    <div className={`formsection ${className} ${noBorder ? "no-border" : ""}`}>
      {title && <h3 className="formsection-title">{title}</h3>}
      {children}
    </div>
  );
}
