import { Navbar, NavbarLinkItem } from "@crunchit/component-library";
import { useTranslation } from "react-i18next";
import EnvironmentBanner from "./EnvironmentBanner";

import "./Header.scss";

interface HeaderProps {
  pageTitleKey: string;
}

export default function Header({ pageTitleKey }: HeaderProps) {
  const { t } = useTranslation();

  return (
    <header id="page-header">
      <EnvironmentBanner />

      <Navbar title={t(`pagetitles:${pageTitleKey}.HeaderTitle`)} className="navbar">
        <div className="navbar-links">
          <NavbarLinkItem to="/receivers" text={t("navigation:Navbar.Receivers")} icon={{ url: "https://crunchorderfrontend.blob.core.windows.net/icons/smile.svg" }} />
          <NavbarLinkItem to="/notifications" text={t("navigation:Navbar.Notifications")} icon={{ url: "https://crunchorderfrontend.blob.core.windows.net/icons/bell.svg" }} />
        </div>

        <div className="navbar-actions">
          <NavbarLinkItem to="/logout" text={t("navigation:Navbar.LogOut")} />
        </div>
      </Navbar>
    </header>
  );
}
