import { Toggle } from "@crunchit/component-library";
import { useTranslation } from "react-i18next";

import "./DetailsStatus.scss";

interface IDetailsStatus {
  isActive: boolean;
  handleUpdate: (newIsActive: boolean) => void;
}

export default function DetailsStatus({ isActive, handleUpdate }: IDetailsStatus) {
  const { t } = useTranslation();

  return (
    <section className="details-status">
      <h1>{t("common:Status")}</h1>

      <Toggle id="toggle" active={isActive} value="active" onChange={(_, newIsActive) => handleUpdate(newIsActive)} title={t(`common:${isActive ? "Active" : "Inactive"}Title`)} />
      <p>{t(`common:${isActive ? "Active" : "Inactive"}`)}</p>
    </section>
  );
}
