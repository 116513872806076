import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { INotificationDto } from "models/notification";
import { IUpdateDto, UpdatableFields } from "models/resource";
import NotificationService from "services/NotificationService";
import { AppContext } from "utils/context";

export default function useNotification(notificationId?: string) {
  const { isLoading: appIsLoading, setError, token } = useContext(AppContext);

  const navigate = useNavigate();

  const [notification, setNotification] = useState<INotificationDto | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const updatableNotificationFields = useMemo(() => {
    const fields: UpdatableFields = new Map();

    if (!notification) {
      return fields;
    }

    fields.set("name", { labelTranslationKey: "notifications:Name", value: notification.name });

    if (notification.filterData) {
      fields.set("filterData", { labelTranslationKey: "notifications:View.FilterData", value: notification.filterData });
    }

    if (notification.cronSchedule) {
      fields.set("cronSchedule", { labelTranslationKey: "notifications:View.CronSchedule", value: notification.cronSchedule });
    }

    return fields;
  }, [notification]);

  const loadNotification = useCallback(async () => {
    if (appIsLoading || !token || !notificationId) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.getNotification(token, notificationId);

      if (!response.isSuccess()) {
        throw new Error();
      }

      setNotification(response.data);
    } catch (error) {
      setError(`Failed to fetch notification: ${error instanceof Error ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }, [appIsLoading, token, notificationId, setError]);

  const updateNotification = useCallback(
    async (updatedFields: UpdatableFields) => {
      if (appIsLoading || !token || !notificationId) {
        return;
      }

      const updates = Array.from(updatedFields);

      if (!updates.length) {
        return;
      }

      setIsLoading(true);

      const updateRequests = updates.map<IUpdateDto>((fieldUpdates) => {
        const [key, data] = fieldUpdates;

        return {
          operationType: "Replace",
          path: key,
          value: data.value,
        };
      });

      try {
        const response = await NotificationService.updateNotification(token, notificationId, updateRequests);

        if (!response.isSuccess()) {
          throw new Error();
        }

        loadNotification();
      } catch (error) {
        setError(`Failed to update notification: ${error instanceof Error ? error.message : error}`);
        setIsLoading(false);
      }
    },
    [appIsLoading, loadNotification, notificationId, setError, token]
  );

  const deleteNotification = useCallback(async () => {
    if (appIsLoading || !token || !notificationId) {
      return;
    }

    setIsLoading(true);

    try {
      const response = await NotificationService.deleteNotification(token, notificationId);

      if (!response.isSuccess()) {
        throw new Error();
      }

      navigate({ pathname: "/notifications" });
    } catch (error) {
      setError(`Failed to delete notification: ${error instanceof Error ? error.message : error}`);
    } finally {
      setIsLoading(false);
    }
  }, [appIsLoading, token, navigate, notificationId, setError]);

  const handleAddReceiver = useCallback(() => {
    loadNotification();
  }, [loadNotification]);

  const handleRemoveReceiverFromNotification = useCallback(
    async (receiverId: string) => {
      if (isLoading || !token || !notificationId) {
        return;
      }

      setIsLoading(true);

      try {
        const response = await NotificationService.deleteReceiverFromNotification(notificationId, receiverId, token);

        if (!response.isSuccess()) {
          throw new Error();
        }

        loadNotification();
      } catch (error) {
        setError(`Failed to remove receiver ${receiverId} from notification ${notificationId}: ${error instanceof Error ? error.message : error}`);
      } finally {
        setIsLoading(false);
      }
    },
    [isLoading, token, notificationId, loadNotification, setError]
  );

  useEffect(() => {
    loadNotification();
  }, [loadNotification]);

  return {
    notification,
    isLoading,
    updatableNotificationFields,
    updateNotification,
    deleteNotification,
    handleAddReceiver,
    handleRemoveReceiverFromNotification,
  };
}
