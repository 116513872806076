import { Route, Routes } from "react-router-dom";

import Receivers from "./Receivers";
import Receiver from "./Receiver";
import Create from "./Create";

import "./Root.scss";

export default function Root() {
  return (
    <Routes>
      <Route path="" Component={Receivers} />
      <Route path="create" Component={Create} />
      <Route path=":id" Component={Receiver} />
    </Routes>
  );
}
