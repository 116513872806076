import { useEffect } from "react";
import { useLocation } from "react-router";
import { useNavigate } from "react-router-dom";

interface IRedirectHookProps {
  isError: boolean;
  isLoading: boolean;
  isAuthenticated: boolean;
}

export default function useAppRedirects(props: IRedirectHookProps) {
  const { isError, isLoading, isAuthenticated } = props;
  const { pathname } = useLocation();

  const navigate = useNavigate();

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (!isAuthenticated && pathname !== "/login") {
      navigate("/login");
    } else if (isAuthenticated && pathname === "/login") {
      navigate("/receivers");
    }
  }, [isLoading, isAuthenticated, pathname, navigate]);

  useEffect(() => {
    if (isError && pathname.indexOf("error") === -1) {
      navigate("/error");
    }
  }, [isError, pathname, navigate]);
}
