import "./EnvironmentBanner.scss";

export default function EnvironmentBanner() {
  const isCI = window.location.href.includes("-ci.azurewebsites.net");
  const isStaging = window.location.href.includes("-staging.azurewebsites.net");

  if (!isCI && !isStaging) {
    return <></>;
  }

  return (
    <div id="environment-banner">
      <p className="name">{isCI ? "ci" : isStaging ? "staging" : ""}</p>
    </div>
  );
}
