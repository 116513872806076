import { useContext, useEffect, useMemo } from "react";

import ErrorMessage from "components/ui/ErrorMessage";
import { browserIsOutdated, logICriticalError, logError } from "utils/helpers/errors";
import { AppContext } from "utils/context";

export default function ErrorPage() {
  const { error, setError } = useContext(AppContext);

  // Checking if the user is in Internet Explorer
  const isBrowserError = useMemo(() => browserIsOutdated(), []);

  useEffect(() => {
    if (error) {
      logICriticalError({ message: error });
    }
  }, [error]);

  useEffect(() => {
    try {
      setError(null);
    } catch (error) {
      logError(error);
    }
  }, [setError]);

  return <ErrorMessage isBrowserError={isBrowserError} />;
}
