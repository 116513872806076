import Radio from "@mui/material/Radio";
import RadioGroup, { RadioGroupProps } from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useState } from "react";
import { IFormInputChange } from "models/form";

interface RadioOptionsProps extends RadioGroupProps {
  options: string[] | number[];
  defaultValue?: string | number;
  className?: string;
  getOptionLabel: (option: string | number) => string;
  handleInputChange: (change: IFormInputChange) => void;
}

export default function RadioOptions(props: RadioOptionsProps) {
  const { options, defaultValue: defaultRadioOption, getOptionLabel, handleInputChange, ...radioGroupProps } = props;

  let [defaultValue] = useState(defaultRadioOption);

  return (
    <RadioGroup {...radioGroupProps} defaultValue={defaultValue} onChange={(event, newValue) => handleInputChange({ inputName: "radio", value: newValue })}>
      {options.map((option) => {
        return <FormControlLabel key={option} value={option} control={<Radio />} label={getOptionLabel(option)} />;
      })}
    </RadioGroup>
  );
}
