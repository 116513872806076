import { useFormControl } from "@mui/material";
import TextField, { OutlinedTextFieldProps } from "@mui/material/TextField";
import { useState } from "react";
import { IFormInputChange } from "models/form";

interface TextInputProps extends Omit<OutlinedTextFieldProps, "variant"> {
  defaultValue?: string;
  className?: string;
  handleInputChange: (change: IFormInputChange) => void;
}

export default function TextInput(props: TextInputProps) {
  const { defaultValue, className = "full", error, handleInputChange, ...restProps } = props;

  let [defaultInputValue] = useState(defaultValue);

  let { error: isShowingValidationErrors = false } = useFormControl() || {};

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleInputChange({ inputName: e.target.name, value: e.target.value });
  };

  return <TextField {...restProps} defaultValue={defaultInputValue} variant="outlined" className={className} onChange={onChange} error={error && isShowingValidationErrors} InputLabelProps={restProps.type === "time" ? { shrink: true } : undefined} />;
}
