import { useContext } from "react";

import SelectInput from "components/ui/Form/inputs/Select";
import { IFormInputChange } from "models/form";
import { INotificationDto } from "models/notification";
import { AppContext } from "utils/context";

interface INotificationSelect {
  label: string;
  currentNotification: INotificationDto | null;
  onSelect: (notification: INotificationDto | null) => void;
  overrideOptions?: INotificationDto[];
}

export default function NotificationSelect({ label, currentNotification, onSelect, overrideOptions }: INotificationSelect) {
  const { notifications } = useContext(AppContext);

  function handleSelect({ value }: IFormInputChange) {
    onSelect(value);
  }

  return <SelectInput className="select notification-select" name="notification" label={label} options={overrideOptions || notifications} defaultValue={currentNotification} getOptionLabel={(option) => option?.name || ""} isOptionEqualToValue={(option, value) => option !== null && value !== null && option.id === value.id} handleInputChange={handleSelect} />;
}
