import { siteStorageName } from "@crunchit/types";
import { getFromLocalStorage, removeFromLocalStorage, saveToLocalStorage } from "@crunchit/utilities";
import { useContext } from "react";

import { authName } from "utils/constants/storage";
import { AppContext } from "utils/context";

export default function useAuth() {
  const { setToken } = useContext(AppContext);

  function getToken(): string | undefined {
    return getFromLocalStorage(siteStorageName, authName);
  }

  const saveToken = (token: string) => {
    setToken(token);
    saveToLocalStorage(siteStorageName, authName, token);
  };

  function signOut() {
    setToken(null);
    removeFromLocalStorage(siteStorageName, authName);
  }

  return {
    getToken,
    saveToken,
    signOut,
  };
}
